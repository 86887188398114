import React, {useState, useEffect} from "react";
import {Link} from 'react-router-dom';
import {Card} from 'react-bootstrap';
function Employee(){
    const [error, setError] = useState(null)
    const [item, setItem] = useState([])

    useEffect(() => {
        fetch("https://jsonplaceholder.typicode.com/users")
        .then(res => res.json())
        .then(
            (result) => {
                setItem(result);
            },
            (error) => {
                setError(error);
            }
        )
    },[])

    if(error) {
        return(
            <div className="container">
                <h1>Error Found in this page</h1>
            </div>
        )
    } else {
        return(
            <div className="container">
                <h1 className="text-primary">Employee List</h1><hr /> 
                <div className="row col-md-12">
                {item.map(item =>
                    <Card className="col-md-4 p-3" key={item.id}>
                        <Card.Header as="h5">{item.name}</Card.Header>
                        <Card.Body>
                            <Card.Title>Username: {item.username}</Card.Title>
                            <Card.Text>
                                <Link to={`/employeeDetails/${item.id}`}>View More</Link>
                            </Card.Text>                            
                        </Card.Body>
                    </Card>
                )}
                </div>
            </div>
        )
    }
}
export default Employee;
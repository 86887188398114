import 'bootstrap/dist/css/bootstrap.min.css';
import {Nav, Container, Navbar} from 'react-bootstrap';
import {Link} from 'react-router-dom';
function Header(){
    return(
        <div>
            <Navbar bg="primary" variant="dark">
                <Container>
                <Navbar.Brand to="/">Navbar</Navbar.Brand>
                <Nav className="me-auto">
                    <Link to="/">Home</Link>
                    <Link to="/product">Product</Link>
                    <Link to="/employee">Employee</Link>
                    <Link to="/about">About Us</Link>
                    <Link to="/contact">Contact Us</Link>
                </Nav>
                </Container>
            </Navbar>
        </div>
    )
}
export default Header;
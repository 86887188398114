import React, {useState, useEffect} from 'react';
function Product(){

    const [error, setError] = useState(null);
    const [items, setItems] = useState([]);

    useEffect(()=>{
        fetch("https://jsonplaceholder.typicode.com/users")
        .then(res => res.json())
        .then(
            (result) => {
                setItems(result);
            },
            (error) => {
                setError(error);
            }
        )
    },[])

    if(error) {
        return(
            <div className='container'>
                <h1>Error Found</h1>
            </div>
        ) 
    } else {
        return(
            <div className='container'>
                <h1>Data from Api</h1><hr />
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Sl#</th>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(item => 
                            <tr key={item.id}>
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                            </tr>
                            )}
                    </tbody>
                </table>                
            </div>
        )
    }
    
}
export default Product;
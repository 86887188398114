import 'bootstrap/dist/css/bootstrap.min.css';
function About() {
  return (
    <div>
      <h1>This is About.js</h1>
    </div>
  );
}

export default About;

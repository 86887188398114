import 'bootstrap/dist/css/bootstrap.min.css';
function Contact() {
  return (
    <div>
      <h1>This is Contact.js</h1>
    </div>
  );
}

export default Contact;

import React, {useState, useEffect} from "react";
import {Link, useParams} from 'react-router-dom';
import {Card} from 'react-bootstrap';
function EmployeeDetails(){

    const [error, setError] = useState(null)
    const [item, setItem] = useState([])
    const params = useParams()

    useEffect(() => {
        fetch(`https://jsonplaceholder.typicode.com/users/${params.id}`)
        .then(res => res.json())
        .then(
            (result) => {
                if (Object.keys(result).length === 0) {
                    window.location = 'http://localhost:3000/employee';                    
                } else {
                setItem(result);
                }
            },
            (error) => {
                setError(error);
                //history.push("/employee");
            }
        )
    },[params.id])
        if(error){
            return <h1>Item not Found</h1>
        } else {
        return(
            <div className="container">
                <h1 className="text-primary">Employee Details</h1><hr />
                <Card>
                    <Card.Header as="h5">{item.name}</Card.Header>
                    <Card.Body>
                        <Card.Title>{item.name}</Card.Title>
                        <Card.Text>
                        <div>Username: {item.username}</div>
                        <div>Email: {item.email}</div>
                        <div>Address: </div>
                        </Card.Text>
                        <Link to="/employee">Back</Link>
                    </Card.Body>
                </Card>
                
            </div>
        )
    }
}
export default EmployeeDetails;

/*address": {
      "street": "Kulas Light",
      "suite": "Apt. 556",
      "city": "Gwenborough",
      "zipcode
      */